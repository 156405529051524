
import OJ0030Service from "@/service/OJ0030Service";
import { Vue } from "vue-class-component";

export default class OJ0030F extends Vue {
    // クラス定義
    OJ0030S: OJ0030Service = new OJ0030Service();

    mounted() {
    }

    fncClickDownload() {
        this.OJ0030S.fncDownload();
    }
}
